// react
import React, { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import debounce from 'debounce';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import toast from 'react-hot-toast';
// Pdf
import { useReactToPrint } from 'react-to-print';
import ImageUploading from 'react-images-uploading';

// styles
import './pdfstyle.css';

// component
import PageLoader from '../pageLoader/PageLoader';

//Image
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import methodologyLogo from '../../assets/images/methodology.jpg';
import pdfLogo from '../../assets/images/pdf.png';
import relianceLogo from '../../assets/images/reliance-logo.png';
import cropAcreageLogo from '../../assets/images/crop-acreage.jpeg';
import cropGrowth from '../../assets/images/crop-growth.png';
import cropLogo from '../../assets/images/sowing-methodology.jpeg';

//helpers
import { database, storage } from '../../utils/firebase-helper.js';
import { ref, set, get, child } from 'firebase/database';
import {
  ref as imageRef,
  listAll,
  uploadBytes,
  deleteObject,
  getDownloadURL
} from 'firebase/storage';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  }
}));

const PdfComponent = () => {
  const user = JSON.parse(localStorage.getItem('user-data'));

  const container = useRef();

  let history = useHistory();
  const classes = useStyles();

  const [regionData, setRegionData] = useState();
  const [regionDistrict, setRegionDistrict] = useState();
  const [regionSeason, setRegionSeason] = useState();
  const [regionPhase, setRegionPhase] = useState();

  const [pdfComponentLoader, setPdfComponentLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const [baseRegion, setBaseRegion] = useState('');
  const [cropsIdentifiedImage, setCropsIdentifiedImage] = useState([]);
  const [studyAreaText, setStudyAreaText] = useState([]);
  const [studyAreaImage, setStudyAreaImage] = useState([]);
  const [rainfallDeviationImage, setRainfallDeviationImage] = useState([]);
  const [cropHealthMonitoring, setcropHealthMonitoring] = useState([]);
  const [pieChat, setPieChat] = useState([]);
  const [ndviMap, setNdviMap] = useState([]);
  const [ndviMapLeg, setNdviMapLeg] = useState([]);
  const [rDLeg, setRDLeg] = useState([]);
  const [ndviChartImage, setNdviChartImage] = useState([]);
  const [ndwiMap, setNdwiMap] = useState([]);
  const [ndwiMapLeg, setNdwiMapLeg] = useState([]);
  const [ndwiChartImage, setNdwiChartImage] = useState([]);
  const [cropPerformanceImage, setCropPerformanceImage] = useState([]);
  const [cropCalendarImage, setCropCalendarImage] = useState([]);
  const [repeatHeaders, setRepeatHeaders] = useState(true);
  const [cropSownArrMapImage, setCropSownArrMap] = useState([]);
  const [cropAcreageMapImage, setCropAcreageMap] = useState([]);
  const [cropHarvestMapImage, setCropHarvestMap] = useState([]);
  const [majorClimateEvent, setMajorClimateEvent] = useState([]);
  const [cropHarvestMapImage2, setCropHarvestMap2] = useState([]);
  const maxLevel = 20; // Set your maximum level here
  const [inputState, setInputState] = useState({});

  const [formatDate, setFormatDate] = useState('');

  const [cropPieChart, setCropPieChart] = useState([]);
  const [fieldPhotosPhase1, setFieldPhotosPhase1] = useState([]);
  const [fieldPhotosPhase2, setFieldPhotosPhase2] = useState([]);
  const [fieldPhotosPhase3, setFieldPhotosPhase3] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const cropPieMaxNumber = 40;

  useEffect(() => {
    setPageLoader(true);
    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();
    setBaseRegion(regionId);
    const urlData = 'https://media.rgi-report.satsure.co/reliance/regions/R025/' + regionId + '/';
    setBaseUrl(urlData);

    const rDLegend = 'https://media.rgi-report.satsure.co/reliance/regions/RainfallDeviation.png';
    setRDLeg(rDLegend);

    const ndviLegend = 'https://media.rgi-report.satsure.co/reliance/regions/Ndvi_Legend.png';
    setNdviMapLeg(ndviLegend);

    const ndwiLegend = 'https://media.rgi-report.satsure.co/reliance/regions/Ndwi_Legend.png';
    setNdwiMapLeg(ndwiLegend);
    fetch('https://media.rgi-report.satsure.co/reliance/regions/R025/' + regionId + '/data.json')
      .then((response) => response.json())
      .then((data) => {
        setTimeout(() => {
          setPdfComponentLoader(true);
        }, 500);
        setTimeout(() => {
          setPageLoader(false);
        }, 10000);
        const regionData = data;
        setRegionData(data);
        setRegionDistrict(data?.regionDistrict);
        setRegionSeason(data?.season);
        setRegionPhase(data?.phase);

        const cropsIdentified = regionData.cropsIdentified;
        setCropsIdentifiedImage(cropsIdentified);
        //
        const studyAreaText = regionData.studyAreaText;
        setStudyAreaText(studyAreaText);
        //
        const studyArea = regionData.studyArea;
        setStudyAreaImage(studyArea);
        //
        const rainfallDeviation = regionData.rainfallDeviation;
        var rainfallDeviationArr = [];
        for (let i in rainfallDeviation) {
          var rainfallDeviationObj = rainfallDeviation[i];
          for (let j in rainfallDeviationObj) {
            var objectrainfallDeviation = {
              img: j,
              label: rainfallDeviationObj[j]
            };
            rainfallDeviationArr.push(objectrainfallDeviation);
          }
        }
        setRainfallDeviationImage(rainfallDeviationArr);
        //
        const cropHealthMonitoring = regionData.cropHealthMonitoring;
        setcropHealthMonitoring(cropHealthMonitoring);
        //setPieChat
        const pieChart = regionData.pieChart;
        setPieChat(pieChart);

        //
        const ndviMap = regionData.ndviMap;
        var ndviArr = [];
        for (let i in ndviMap) {
          var ndviMapObj = ndviMap[i];
          for (let j in ndviMapObj) {
            var object = { img: j, label: ndviMapObj[j] };
            ndviArr.push(object);
          }
        }
        setNdviMap(ndviArr);
        //
        const ndviChart = regionData.ndviChart;
        setNdviChartImage(ndviChart);
        // ndwiMap
        const ndwiMap = regionData.ndwiMap;
        var ndwiArr = [];
        for (let i in ndwiMap) {
          var ndwiMapObj = ndwiMap[i];
          for (let j in ndwiMapObj) {
            var object1 = { img: j, label: ndwiMapObj[j] };
            ndwiArr.push(object1);
          }
        }
        setNdwiMap(ndwiArr);
        //
        const ndwiChart = regionData.ndwiChart;
        setNdwiChartImage(ndwiChart);
        //
        const cropPerformance = regionData.cropPerformance;
        setCropPerformanceImage(cropPerformance);
        //
        const cropCalendar = regionData.cropCalendar;
        setCropCalendarImage(cropCalendar);
        // CropAcreage
        const cropAcreageArr = regionData.CropAcreage;
        setCropAcreageMap(cropAcreageArr);
        //CropSown
        const cropSownPercentageArr = regionData.CropSown;
        setCropSownArrMap(cropSownPercentageArr);
        // CropHarvest
        const cropHarvestMapArr = regionData.CropHarvest;
        setCropHarvestMap(cropHarvestMapArr);
        //cropharvest2
        const cropHarvestMapArr2 = regionData.CropHarvest2;
        setCropHarvestMap2(cropHarvestMapArr2);
      });
   
  }, []);

//  useEffect(()=>{
//     if(regionData && Object.keys(inputState).length!==0 ){
//       fetchPercentage()
//     }
//   },[regionData,inputState])
  
   
  


  

  useEffect(() => {
    if (regionSeason) {
      fetchPieChart();
      fetchPhase1();
      fetchPhase2();
      fetchPhase3();
    }
  }, [regionSeason, trigger]);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(database);
      try {
        var url = window.location.href;
        var splitData = url.split('/');
        var regionId = splitData.pop();

        const snapshot = await get(child(dbRef, `RGI/${regionSeason}/${regionId}`));
        if (snapshot.exists()) {
          setFormatDate(snapshot.val()?.publishDate);
          fetchPercentage(snapshot.val())
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    if (regionSeason) {
      fetchData();
    }
  }, [regionSeason]);

  const fetchPercentage=(inputForm)=>{
    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();
    fetch('https://media.rgi-report.satsure.co/reliance/regions/R025/' + regionId + '/percentage.json')
    .then((response) => response.json()).then((data)=>{

      let formData= {...inputForm}

      if(!inputForm?.cropAcreageOverall1){
        formData.cropAcreageOverall1 = data?.CropAcreage[regionData?.CropAcreage[0]?.crop] ? data?.CropAcreage[regionData?.CropAcreage[0]?.crop] :""
      }
      if(!inputForm?.cropAcreageOverall2){
        formData.cropAcreageOverall2 = data?.CropAcreage[regionData?.CropAcreage[1]?.crop]? data?.CropAcreage[regionData?.CropAcreage[1]?.crop] : ""
      }
      if(!inputForm?.cropAcreageOverall3){
        formData.cropAcreageOverall3 = data?.CropAcreage[regionData?.CropAcreage[2]?.crop] ?data?.CropAcreage[regionData?.CropAcreage[2]?.crop] :""
      }

      if(!inputForm?.cropAcreageOverall4){
        formData.cropAcreageOverall4 = data?.CropAcreage[regionData?.CropAcreage[3]?.crop] ? data?.CropAcreage[regionData?.CropAcreage[3]?.crop] :""
      }
      if(!inputForm?.cropHarvestOverall1){
        formData.cropHarvestOverall1 = data?.CropHarvest[regionData?.CropHarvest[0]?.crop] ? data?.CropHarvest[regionData?.CropHarvest[0]?.crop] :""
      }
      if(!inputForm?.cropHarvestOverall2){
        formData.cropHarvestOverall2 = data?.CropHarvest[regionData?.CropHarvest[1]?.crop] ? data?.CropHarvest[regionData?.CropHarvest[1]?.crop] :""
      }
      if(!inputForm?.cropHarvestOverall3){
        formData.cropHarvestOverall3 = data?.CropHarvest[regionData?.CropHarvest[2]?.crop] ? data?.CropHarvest[regionData?.CropHarvest[2]?.crop] :""
      }

      setInputState({...formData})

      
    })
  }

  const fetchPieChart = async () => {
    setCropPieChart([]);
    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();

    const refrence = imageRef(storage, `${regionSeason}/${regionId}/pieChart/`);
    try {
      listAll(refrence).then((response) => {
        let images = response.items.map((item) => getDownloadURL(item));
        Promise.all(images).then((urls) => {
          const uniqueImages = new Map();

          urls.forEach((url) => {
            const tempUrl = new URL(url);
            const id = tempUrl.searchParams.get('token');

            if (!uniqueImages.has(id)) {
              uniqueImages.set(id, { data_url: url, id });
            }
          });
          setCropPieChart(Array.from(uniqueImages.values()));
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPhase1 = () => {
    setFieldPhotosPhase1([]);
    const url = window.location.href;
    const splitData = url.split('/');
    const regionId = splitData.pop();

    const reference = imageRef(storage, `${regionSeason}/${regionId}/fieldImages/phase1`);
    try {
      listAll(reference).then((response) => {
        const imagePromises = response.items.map((item) => getDownloadURL(item));
        Promise.all(imagePromises).then((urls) => {
          // Use a Set to collect unique images
          const uniqueImages = new Map();

          urls.forEach((url) => {
            const tempUrl = new URL(url);
            const id = tempUrl.searchParams.get('token');

            if (!uniqueImages.has(id)) {
              uniqueImages.set(id, { data_url: url, id });
            }
          });

          setFieldPhotosPhase1(Array.from(uniqueImages.values()));
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPhase2 = () => {
    setFieldPhotosPhase2([]);
    const url = window.location.href;
    const splitData = url.split('/');
    const regionId = splitData.pop();

    const reference = imageRef(storage, `${regionSeason}/${regionId}/fieldImages/phase2`);
    try {
      listAll(reference).then((response) => {
        const imagePromises = response.items.map((item) => getDownloadURL(item));
        Promise.all(imagePromises).then((urls) => {
          // Use a Set to collect unique images
          const uniqueImages = new Map();

          urls.forEach((url) => {
            const tempUrl = new URL(url);
            const id = tempUrl.searchParams.get('token');

            if (!uniqueImages.has(id)) {
              uniqueImages.set(id, { data_url: url, id });
            }
          });

          setFieldPhotosPhase2(Array.from(uniqueImages.values()));
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPhase3 = () => {
    setFieldPhotosPhase3([]);
    const url = window.location.href;
    const splitData = url.split('/');
    const regionId = splitData.pop();

    const reference = imageRef(storage, `${regionSeason}/${regionId}/fieldImages/phase3`);
    try {
      listAll(reference).then((response) => {
        const imagePromises = response.items.map((item) => getDownloadURL(item));
        Promise.all(imagePromises).then((urls) => {
          // Use a Set to collect unique images
          const uniqueImages = new Map();

          urls.forEach((url) => {
            const tempUrl = new URL(url);
            const id = tempUrl.searchParams.get('token');

            if (!uniqueImages.has(id)) {
              uniqueImages.set(id, { data_url: url, id });
            }
          });

          setFieldPhotosPhase3(Array.from(uniqueImages.values()));
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeCropPieChart = (imageList, addUpdateIndex) => {
    if (addUpdateIndex) {
      var url = window.location.href;
      var splitData = url.split('/');
      var regionId = splitData.pop();

      // Reference to the folder in Firebase Storage
      const folderRef = imageRef(storage, `${regionSeason}/${regionId}/pieChart/`);

      // List all files in the pieChart folder
      listAll(folderRef)
        .then((response) => {
          // Get existing file names in the pieChart folder
          let existingFileNames = response.items.map((item) => item.name);

          // Loop through each selected file for pie chart
          addUpdateIndex.map((element) => {
            const fileToUpload = imageList[element].file;
            const fileName = fileToUpload.name;

            // Check if the file already exists in the folder
            if (existingFileNames.includes(fileName)) {
              toast.error(`File ${fileName} already exists. Skipping upload.`);
            } else {
              // If the file does not exist, upload it
              const fileRef = imageRef(storage, `${regionSeason}/${regionId}/pieChart/${fileName}`);

              uploadBytes(fileRef, fileToUpload).then(() => {
                toast.success(`Successfully uploaded ${fileName}`);
                setTrigger((prev) => !prev); // Toggle trigger after upload
              });
            }
          });
        })
        .catch((error) => {
          console.error('Error listing files:', error);
        });
    } else {
      onPieChartRemove('pieChart');
    }
  };

  const onHandlePhase1 = async (imageList, appUpdateIndex) => {
    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();

    const folderRef = imageRef(storage, `${regionSeason}/${regionId}/fieldImages/phase1/`);

    if (appUpdateIndex) {
      // Reference to the folder in Firebase Storage

      // First, list all files in the folder
      listAll(folderRef)
        .then((response) => {
          // Get the existing file names in the folder
          let existingFileNames = response.items.map((item) => item.name);

          // Loop through each selected file
          appUpdateIndex.forEach((element) => {
            const fileToUpload = imageList[element].file;
            var fileName = fileToUpload.name;

            // Check if the file already exists
            if (existingFileNames.includes(fileName)) {
              toast.error(`File ${fileName} already exists. Skipping upload.`);
            } else {
              const fileRef = imageRef(
                storage,
                `${regionSeason}/${regionId}/fieldImages/phase1/${fileName}`
              );

              uploadBytes(fileRef, fileToUpload).then(() => {
                toast.success(`Successfully uploaded ${fileName}`);
                setTrigger((prev) => !prev); // Toggle trigger after upload
              });
            }
          });
        })
        .catch((error) => {
          console.error('Error listing files:', error);
        });
    } else {
      const result = await listAll(folderRef);

      const deletePromises = result.items.map((item) => deleteObject(item));

      await Promise.all(deletePromises).then(() => {
        console.log('Successfully removed');
        setFieldPhotosPhase1([]);
      });
    }
  };

  const onHandlePhase2 = async (imageList, appUpdateIndex) => {
    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();

    // Reference to the folder in Firebase Storage
    const folderRef = imageRef(storage, `${regionSeason}/${regionId}/fieldImages/phase2/`);
    if (appUpdateIndex) {
      // First, list all files in the folder
      listAll(folderRef)
        .then((response) => {
          // Get the existing file names in the folder
          let existingFileNames = response.items.map((item) => item.name);

          // Loop through each selected file
          appUpdateIndex.forEach((element) => {
            const fileToUpload = imageList[element].file;
            var fileName = fileToUpload.name;

            // Check if the file already exists
            if (existingFileNames.includes(fileName)) {
              toast.error(`File ${fileName} already exists. Skipping upload.`);
            } else {
              const fileRef = imageRef(
                storage,
                `${regionSeason}/${regionId}/fieldImages/phase2/${fileName}`
              );

              uploadBytes(fileRef, fileToUpload).then(() => {
                toast.success(`Successfully uploaded ${fileName}`);
                setTrigger((prev) => !prev); // Toggle trigger after upload
              });
            }
          });
        })
        .catch((error) => {
          console.error('Error listing files:', error);
        });
    } else {
      const result = await listAll(folderRef);

      const deletePromises = result.items.map((item) => deleteObject(item));

      await Promise.all(deletePromises).then(() => {
        console.log('Successfully removed');
        setFieldPhotosPhase2([]);
      });
    }
  };

  const onHandlePhase3 = async (imageList, appUpdateIndex) => {
    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();

    // Reference to the folder in Firebase Storage
    const folderRef = imageRef(storage, `${regionSeason}/${regionId}/fieldImages/phase3`);
    if (appUpdateIndex) {
      // First, list all files in the folder
      listAll(folderRef)
        .then((response) => {
          // Get the existing file names in the folder
          let existingFileNames = response.items.map((item) => item.name);

          // Loop through each selected file
          appUpdateIndex.forEach((element) => {
            const fileToUpload = imageList[element].file;
            var fileName = fileToUpload.name;

            // Check if the file already exists
            if (existingFileNames.includes(fileName)) {
              toast.error(`File ${fileName} already exists. Skipping upload.`);
            } else {
              const fileRef = imageRef(
                storage,
                `${regionSeason}/${regionId}/fieldImages/phase3/${fileName}`
              );

              uploadBytes(fileRef, fileToUpload).then(() => {
                toast.success(`Successfully uploaded ${fileName}`);
                setTrigger((prev) => !prev); // Toggle trigger after upload
              });
            }
          });
        })
        .catch((error) => {
          console.error('Error listing files:', error);
        });
    } else {
      const result = await listAll(folderRef);

      const deletePromises = result.items.map((item) => deleteObject(item));

      await Promise.all(deletePromises).then(() => {
        console.log('Successfully removed');
        setFieldPhotosPhase3([]);
      });
    }
  };

  const onPieChartRemove = async (type) => {
    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();

    const refrence = imageRef(storage, `${regionSeason}/${regionId}/${type}`);
    try {
      const result = await listAll(refrence);

      const deletePromises = result.items.map((item) => deleteObject(item));

      await Promise.all(deletePromises).then(() => {
        console.log('Successfully removed');
        if (type === 'pieChart') {
          setCropPieChart([]);
        }
        // if (type === 'fieldImages') {
        //   setFieldPhotos([]);
        // }
      });
    } catch (e) {
      console.log(e);
    }
  };

  //Back
  const btnCallBack = () => {
    history.replace(`/landing`);
  };

  const onHandlePublish = () => {
    let formData = { ...inputState };

    formData['status'] = 'ready';

    setInputState({ ...formData });

    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();

    if (regionSeason) {
      set(ref(database, `RGI/${regionSeason}/${regionId}`), formData)
        .then(() => {
          toast.success('successfully published..!');
        })
        .catch(() => {
          toast.error('failed to publish');
        });
    }
  };

  const exportPDFWithComponent = useReactToPrint({
    content: () => container.current
  });

  // Weather Outlook
  const weatherOutlookChunkArray = (array, size) => {
    const wOChunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      wOChunkedArray.push(array.slice(i, i + size));
    }
    return wOChunkedArray;
  };

  // Crop Pie Chart
  const cropPieChartChunkArray = (array, size) => {
    const cropPieChartArray = [];
    for (let i = 0; i < array.length; i += size) {
      cropPieChartArray.push(array.slice(i, i + size));
    }
    return cropPieChartArray;
  };

  // NDVI
  const ndviChunkArray = (array, size) => {
    const ndviChunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      ndviChunkedArray.push(array.slice(i, i + size));
    }
    return ndviChunkedArray;
  };

  const ndviLineChartChunkArray = (array, size) => {
    const ndviLineChartChunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      ndviLineChartChunkedArray.push(array.slice(i, i + size));
    }
    return ndviLineChartChunkedArray;
  };

  // NDWI
  const ndWiChunkArray = (array, size) => {
    const ndwiChunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      ndwiChunkedArray.push(array.slice(i, i + size));
    }
    return ndwiChunkedArray;
  };

  const ndwiLineChartChunkArray = (array, size) => {
    const ndwiLineChartChunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      ndwiLineChartChunkedArray.push(array.slice(i, i + size));
    }
    return ndwiLineChartChunkedArray;
  };

  const onChangeHandler = (e) => {
    let formData = { ...inputState };

    formData[e.target.name] = e.target.value;

    setInputState({ ...formData });

    var url = window.location.href;
    var splitData = url.split('/');
    var regionId = splitData.pop();

    if (regionSeason) {
      set(ref(database, `RGI/${regionSeason}/${regionId}`), formData);
    }
  };

  const debouncedOnChange = debounce(onChangeHandler, 500);

  //Dynamic Table of Content
  const getWeatherCount = () => {
    if (rainfallDeviationImage.length % 4 === 0) {
      return 3 + Math.floor(rainfallDeviationImage.length / 4);
    } else {
      return 3 + Math.floor(rainfallDeviationImage.length / 4) + 1;
    }
  };

  const getGroundRainWaterPage = () => {
    return getWeatherCount() + 1;
  };

  const getCHMPage = () => {
    return getGroundRainWaterPage() + 1;
  };

  const getGroundObs = () => {
    return getCHMPage() ;
  };

  const getCommonPage = () => {
    return getGroundObs() + 2;
  };

  const getPieChartCount = () => {
    if (cropPieChart.length % 4 === 0) {
      return getCommonPage() + Math.floor(cropPieChart.length / 4);
    } else {
      return getCommonPage() + Math.floor(cropPieChart.length / 4) + 1;
    }
  };

  const getNdviPage = () => {
    return cropPieChart.length ? getPieChartCount() : getCommonPage();
  };

  const getNdwiPage = () => {
    if (ndviMap.length % 6 === 0) {
      return getNdviPage() + Math.floor(ndviMap.length / 6) + ndviChartImage.length;
    } else {
      return getNdviPage() + Math.floor(ndviMap.length / 6) + ndviChartImage.length + 1;
    }
  };

  const getCropSowingPage = () => {
    if (ndwiMap.length % 6 === 0) {
      return getNdwiPage() + Math.floor(ndwiMap.length / 6);
    } else {
      return getNdwiPage() + Math.floor(ndwiMap.length / 6) + ndviChartImage.length + 1;
    }
  };

  const getCropAcreagePage = () => {
    return getCropSowingPage() + cropSownArrMapImage.length * 2;
  };

  const getCropHarvestPage = () => {
    return getCropAcreagePage() + cropAcreageMapImage.length * 2;
  };

  const getCropHarvestPage2 = () => {
    return getCropHarvestPage() + cropHarvestMapImage.length * 2;
  };

  const getFieldPhotographyPage = () => {
    // if (isCropHarvest2Included) {
    //   return getCropHarvestPage2() + cropHarvestMapImage.length * 2;
    // } else {
    //   return getCropHarvestPage() + cropHarvestMapImage.length * 2;
    // }
    return getCropHarvestPage() + cropHarvestMapImage.length * 2
  };

  const getPhase1Count = () => {
    if (fieldPhotosPhase1.length) {
      if (fieldPhotosPhase1.length % 4 === 0) {
        return getFieldPhotographyPage() + Math.floor(fieldPhotosPhase1.length / 4);
      } else {
        return getFieldPhotographyPage() + Math.floor(fieldPhotosPhase1.length / 4) + 1;
      }
    } else {
      return getFieldPhotographyPage() + Math.floor(fieldPhotosPhase1.length / 4);
    }
  };

  const getPhase2Count = () => {
    if (fieldPhotosPhase2.length) {
      if (fieldPhotosPhase2.length % 4 === 0) {
        return getPhase1Count() + Math.floor(fieldPhotosPhase2.length / 4);
      } else {
        return getPhase1Count() + Math.floor(fieldPhotosPhase2.length / 4) + 1;
      }
    } else {
      return getPhase1Count() + Math.floor(fieldPhotosPhase2.length / 4);
    }
  };

  const getPhase3Count = () => {
    if (fieldPhotosPhase3.length) {
      if (fieldPhotosPhase3.length % 4 === 0) {
        return getPhase2Count() + Math.floor(fieldPhotosPhase3.length / 4);
      } else {
        return getPhase2Count() + Math.floor(fieldPhotosPhase3.length / 4) + 1;
      }
    } else {
      return getPhase2Count() + Math.floor(fieldPhotosPhase3.length / 4);
    }
  };

  const getConclusionPage = () => {
    return getPhase3Count() ;
  };

  const getAnexurePage = () => {
    return getConclusionPage() + 1;
  };

  //Dynamic Heading
  const getHeadingNdvi = () => {
    return (
      <>
        <li>
          <Link to="normalized" spy={true} smooth={true}>
            <label>7. Crop Wise - Physical Appearance </label>
            <label className="chapter">{getCommonPage()}</label>
          </Link>
        </li>

        <li>
          <Link to="waterindex" spy={true} smooth={true}>
            <label>8. Normalized Difference Vegetation Index</label>
            <label className="chapter">{getNdviPage()}</label>
          </Link>
        </li>
        <li>
          <Link to="waterindex" spy={true} smooth={true}>
            <label>9. Normalized Difference Water Index</label>
            <label className="chapter">{getNdwiPage()}</label>
          </Link>
        </li>
        <li>
          <Link to="waterindex" spy={true} smooth={true}>
            <label>10. Crop Sowing</label>
            <label className="chapter">{getCropSowingPage()}</label>
          </Link>
        </li>
        <li>
          <Link to="waterindex" spy={true} smooth={true}>
            <label>11. Crop Acreage</label>
            <label className="chapter">{getCropAcreagePage()}</label>
          </Link>
        </li>
        <li>
          <Link to="harvest" spy={true} smooth={true}>
            <label>12. Crop Harvest 1</label>
            <label className="chapter">{getCropHarvestPage()}</label>
          </Link>
        </li>

 {/*
        {isCropHarvest2Included && (
          <li>
            <Link to="harvest2" spy={true} smooth={true}>
              <label>13. Crop Harvest 2</label>
              <label className="chapter">{getCropHarvestPage2() + 1}</label>
            </Link>
          </li>
        )} */}
      </>
    );
  };


  if (
    (user && user?.user_type === 'external' && inputState.status === 'ready') ||
    (user && user?.user_type === 'internal')
  ) {
    if (pdfComponentLoader) {
      return (
        <div className="pdf-sections">
          <div className="back-btn">
            <Button
              onClick={btnCallBack}
              variant="contained"
              className={classes.button}
              startIcon={<ArrowBackIcon />}>
              Back
            </Button>
            {user?.user_type === 'internal' && (
              <Button
                onClick={() => onHandlePublish()}
                variant="contained"
                className={classes.button}>
                Publish
              </Button>
            )}
          </div>
          {/* Export Pdf */}
          {pageLoader ? null : (
            <div className="export">
              <div
                className="export-pdf"
                onClick={exportPDFWithComponent}
                id="repeatHeaders"
                onChange={() => setRepeatHeaders(!repeatHeaders)}>
                <label htmlFor="repeatHeaders">
                  <img src={pdfLogo} alt="Satsure Logo" />
                </label>
              </div>
              {/* <div className="export-doc" onClick={exportDoc}>
              <label>
                <img alt="doclogo" src={docLogo} />
              </label>
            </div> */}
            </div>
          )}
          <div id="exportContent" ref={container}>
            <div className="report-wrapper">
              {/*  Header Logo  */}
              <div className="header-logo">
                <div className="logo-icon">
                  <img src={relianceLogo} alt="Satsure Logo" />
                </div>
              </div>
              {/* Title */}
              <div className="tittlebottom">
                <div className="margin-bottom-30 report-container">
                  <div className="report-sections">
                    <div className="report-bg">
                      {regionData?.titleBackground.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="report-bg-img">
                            <img src={urlImg} alt="crop Logo" />
                          </div>
                        );
                      })}
                    </div>
                    <ul className="district_published">
                      <li>
                        <label>District - </label>
                        <span>
                          <input
                            className="input-text"
                            type="text"
                            value={regionDistrict}
                            onChange={(e) => {
                              setRegionDistrict(e.target.value);
                            }}
                          />
                        </span>
                      </li>
                      <li>
                        <label>Published on : </label>
                        <span className="report-published">
                          <input
                            className="input-text"
                            type="text"
                            name="publishDate"
                            defaultValue={inputState?.publishDate ? inputState?.publishDate : null}
                            onChange={debouncedOnChange}
                          />
                        </span>
                      </li>
                    </ul>
                    <div className="report-seasons">
                      <label>
                        <input
                          className="input-text"
                          name="season"
                          type="text"
                          defaultValue={inputState?.season ? inputState?.season : regionSeason}
                          onChange={debouncedOnChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="report-content-sections">
                {/* Table Content */}
                <div className="nobottom">
                  <div className="table-contents page-break">
                    <label className="table-content-title">Table of Contents</label>
                    <ul className="margin-bottom-30 table-content-list">
                      <li>
                        <Link to="introduction" spy={true} smooth={true}>
                          <label className="title">1. Introduction</label>
                          <label className="chapter">1</label>
                        </Link>
                      </li>
                      <li>
                        <Link to="scope" spy={true} smooth={true}>
                          <label>2. Objective and Scope of Work</label>
                          <label className="chapter">1</label>
                        </Link>
                      </li>
                      <li>
                        <Link to="material" spy={true} smooth={true}>
                          <label>3. Methodology</label>
                          <label className="chapter">1</label>
                        </Link>
                      </li>
                      <li>
                        <Link to="studyarea" spy={true} smooth={true}>
                          <label>4. Study Area</label>
                          <label className="chapter">2</label>
                        </Link>
                        <ul className="table-content-heading">
                          <li>
                            <Link to="normalized" spy={true} smooth={true}>
                              <label>4.1 Geographical Distribution</label>
                              <label className="chapter">2</label>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="weatheroutlook" spy={true} smooth={true}>
                          <label>5. Weather Outlook</label>
                          <label className="chapter">3</label>
                        </Link>
                        <ul className="table-content-heading">
                          {/* <li>
                          <Link to="normalized" spy={true} smooth={true}>
                            <label>5.1 Major Climate Events</label>
                            <label className="chapter">
                            
                            </label>
                          </Link>
                        </li> */}
                          <li>
                            <Link to="normalized" spy={true} smooth={true}>
                              <label>5.1 Rainfall & Ground Water Trend</label>
                              <label className="chapter">{getWeatherCount()}</label>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="healthmonitoring" spy={true} smooth={true}>
                          <label> 6. Crop Health Monitoring (CHM)</label>
                          <label className="chapter">{getCHMPage()}</label>
                        </Link>
                        <ul className="table-content-heading">
                          <li>
                            <Link to="normalized" spy={true} smooth={true}>
                              <label>6.1 Ground Truthing Observation</label>
                              <label className="chapter">{getGroundObs()}</label>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {getHeadingNdvi()}

                      <li>
                        <Link to="photographs" spy={true} smooth={true}>
                          <label>
                            13. Field Photographs
                          </label>
                          <label className="chapter">
                            {getFieldPhotographyPage()}
                          </label>
                        </Link>
                      </li>

                      <li>
                        <Link to="conclusion" spy={true} smooth={true}>
                          <label>14. Conclusion</label>
                          <label className="chapter">
                            {getConclusionPage()}
                          </label>
                        </Link>
                      </li>

                      <li>
                        <Link to="annexure" spy={true} smooth={true}>
                          <label>15. Annexure</label>
                          <label className="chapter">
                            {getAnexurePage()}
                          </label>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Introduction */}
                <div className="nobottom page-break">
                  <h3 className="common-heading left" id="introduction">
                    1. Introduction
                  </h3>
                  <p className="common-para left">
                    The national ﬂagship crop insurance program Pradhan Mantri Fasal Bima Yojana
                    (PMFBY) is in its{' '}
                    <input
                      className="input-min-text intro"
                      type="text"
                      defaultValue={inputState.introDate ? inputState.introDate : null}
                      style={{ width: '85px' }}
                      name="introDate"
                      onChange={debouncedOnChange}
                    />
                    {'  '}
                    of successful implementation in the country and for improving execution of the
                    Scheme, Operational Guidelines directs Stake Holders to adopt and integrate
                    various technology based solutions. With the civilian use of Remote Sensing (RS)
                    technology gaining wider acceptance, studies towards improving techniques to
                    generate quantitative in- season indices on crops is now possible. Estimates on
                    crop sown acreage, monitoring crop health, mid-season adversity assessment,
                    harvest progression, smart sampling for Crop cutting experiments and
                    implementation of suitable models for yield estimation are being attempted.
                  </p>
                  <p className="common-para left">
                    Reliance General Insurance Company Limited (RGICL) has set up its in-house team
                    to develop studies on remote sensing and GIS technology and has engaged the ﬁeld
                    team to continuously obtain ground information on agricultural land for
                    compiling comprehensive integrated quantitative information on crop health
                    parameters from sowing till harvest.
                  </p>
                  <p className="common-para left">
                    The following report is prepared by Geographical Information System (GIS)
                    Analytics team of RGICL for crop health monitoring studies in{' '}
                    {regionData?.regionDistrict} district of {regionData?.regionState} state for{' '}
                    Rabi 2024 - 25 crop season. The study is being conducted through an
                    integrated approach of ﬁeld- based information, input data from Government and
                    satellite images. Attempt is made to cover major crops and generate periodical
                    reports on crop acreage, condition and growth progression.
                  </p>
                  {/*  Objective and Scope of Work */}
                  <div className="scope">
                    <h3 className="common-heading" id="scope">
                      2. Objective and Scope of Work
                    </h3>
                    <p className="common-para">
                      To continuously track the crop growth stages from sowing to harvesting and
                      initiate appropriate proactive corrective measures on the field with the
                      support of respective departments on real time basis. The scope of work is
                      limited to major crops in the notified area of PMFBY for the crop season.
                    </p>
                  </div>

                  {/* <!-- Material and Methodology --> */}
                  <h3 id="material" className="common-heading">
                    3. Methodology
                  </h3>
                  <p className="common-para">
                    The Ground Truthing information is sourced through the feet on field resources
                    on mobile platform with georeferenced field attributes on crop sown, general
                    crop condition, potential risks to crop reaching maturity stage, information
                    pertaining to any local incidents causing crop loss.
                  </p>
                  <div className="methodology_logo">
                    <img src={methodologyLogo} alt="methodology Logo" />
                  </div>
                  <label className="figures">Exhibit : Methodology of Crop Health Monitoring</label>
                  <label className="number"></label>
                </div>

                {/* <!-- Study Area --> */}
                <div>
                  <div className="nobottom">
                    <h3 id="studyarea" className="page-break common-heading">
                      4. Study Area
                    </h3>
                    <p className="common-para">
                      <TextareaAutosize
                        className="study-area"
                        aria-label="empty textarea"
                        maxLength="1000"
                        minRows={8}
                        placeholder="Max 1000 characters"
                        defaultValue={
                          inputState?.studyAreaText ? inputState.studyAreaText : studyAreaText
                        }
                        style={{ width: '100%' }}
                        name={'studyAreaText'}
                        onChange={debouncedOnChange}
                      />
                    </p>

                    {studyAreaImage.length !== 0 ? (
                      <div className="common-img-sections">
                        {studyAreaImage.map((data, key) => {
                          var urlImg = baseUrl + data;
                          return (
                            <div className="common-img-resolutions">
                              <img src={urlImg} alt="crop Logo" />
                            </div>
                          );
                        })}
                        <label className="figures">Exhibit : Study Area</label>
                      </div>
                    ) : null}

                    {cropsIdentifiedImage.length !== 0 ? (
                      <div className="crop-identify">
                        {cropsIdentifiedImage.map((data, key) => {
                          var urlImg = baseUrl + data;
                          return (
                            <div className="common-img-resolutions">
                              <img src={urlImg} alt="crop Logo" />
                            </div>
                          );
                        })}
                        <label className="figures">Table 1 : Geographical Information</label>
                      </div>
                    ) : null}

                    <h3 className="common-heading">4.1 Geographical Distribution</h3>
                    <div className="dynamic-table">
                      <table>
                        <thead>
                          <tr>
                            <th>District {regionData?.regionDistrict}</th>
                            <th>Geographical</th>
                            <th>Net Sown</th>
                            <th>Gross Cropped</th>
                            <th>Cropping Intensity (%)</th>
                            <th>Gross Irrigated</th>
                            <th>Net Irrigated</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="dynamic_td">
                            <td>
                              <input
                                name="district1"
                                onChange={debouncedOnChange}
                                defaultValue={inputState?.district1 ? inputState.district1 : null}
                              />
                            </td>
                            <td>
                              <input
                                name="geographical1"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.geographical1 ? inputState.geographical1 : null
                                }
                              />
                            </td>
                            <td>
                              <input
                                name="netSown1"
                                onChange={debouncedOnChange}
                                defaultValue={inputState?.netSown1 ? inputState.netSown1 : null}
                              />
                            </td>
                            <td>
                              <input
                                name="grossCropped1"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.grossCropped1 ? inputState.grossCropped1 : null
                                }
                              />
                            </td>
                            <td>
                              <input
                                name="croppingIntensity1"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.croppingIntensity1
                                    ? inputState.croppingIntensity1
                                    : null
                                }
                              />
                            </td>
                            <td>
                              <input
                                name="grossIrrigated1"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.grossIrrigated1 ? inputState.grossIrrigated1 : null
                                }
                              />
                            </td>
                            <td>
                              <input
                                name="netIrrigated1"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.netIrrigated1 ? inputState.netIrrigated1 : null
                                }
                              />
                            </td>
                          </tr>
                          <tr className="dynamic_td">
                            <td>
                              <input
                                name="district2"
                                onChange={debouncedOnChange}
                                defaultValue={inputState?.district2 ? inputState.district2 : null}
                              />
                            </td>
                            <td>
                              <input
                                name="geographical2"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.geographical2 ? inputState.geographical2 : null
                                }
                              />
                            </td>
                            <td>
                              <input
                                name="netSown2"
                                onChange={debouncedOnChange}
                                defaultValue={inputState?.netSown2 ? inputState.netSown2 : null}
                              />
                            </td>
                            <td>
                              <input
                                name="grossCropped2"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.grossCropped2 ? inputState.grossCropped2 : null
                                }
                              />
                            </td>
                            <td>
                              <input
                                name="croppingIntensity2"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.croppingIntensity2
                                    ? inputState.croppingIntensity2
                                    : null
                                }
                              />
                            </td>
                            <td>
                              <input
                                name="grossIrrigated2"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.grossIrrigated2 ? inputState.grossIrrigated2 : null
                                }
                              />
                            </td>
                            <td>
                              <input
                                name="netIrrigated2"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.netIrrigated2 ? inputState.netIrrigated2 : null
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <label className="number"></label>
                  </div>
                </div>

                {rainfallDeviationImage.length !== 0 ? (
                  <>
                    {weatherOutlookChunkArray(rainfallDeviationImage, 4).map(
                      (group, groupIndex) => (
                        <div className="page-break nobottom">
                          {0 === groupIndex && (
                            <>
                              <h3 id="weatheroutlook" className="common-heading">
                                5. Weather Outlook
                              </h3>
                              <div className="text-area">
                                <TextareaAutosize
                                  aria-label="empty textarea"
                                  maxLength="850"
                                  minRows={6}
                                  placeholder="Max 850 characters"
                                  defaultValue={
                                    inputState?.weatheroutlookheading
                                      ? inputState.weatheroutlookheading
                                      : null
                                  }
                                  style={{ width: '100%' }}
                                  name={'weatheroutlookheading'}
                                  onChange={debouncedOnChange}
                                />
                              </div>
                            </>
                          )}
                          <div className="common-img-sections">
                            <div key={groupIndex}>
                              <ul className="ndvi-images">
                                {group.map((item) => {
                                  var urlImg = baseUrl + item.img;
                                  return (
                                    <li className="ndvi_ndwi">
                                      <div className="common-img-resolutions fortnight">
                                        <img src={urlImg} alt="Satsure Logo" />
                                      </div>
                                      <label className="figures">{item.label}</label>
                                    </li>
                                  );
                                })}
                              </ul>
                              <div className="common-img-resolutions ndi-img">
                                <img src={rDLeg} alt="ndvi leg" />
                              </div>
                            </div>
                            {weatherOutlookChunkArray(rainfallDeviationImage, 4).length - 1 ===
                              groupIndex && (
                              <>
                                <label className="figures">
                                  (Source: Indian Meteorological Department (IMD))
                                </label>
                                <label className="figures">
                                  Exhibit : Tehsil-Wise Rainfall departure (%) Map of{' '}
                                  {regionData?.regionDistrict} district during{' '}
                                  <input
                                    className="input-min-text exhibit"
                                    type="text"
                                    defaultValue={
                                      inputState?.weatherExhibit ? inputState.weatherExhibit : null
                                    }
                                    name="weatherExhibit"
                                    onChange={debouncedOnChange}
                                  />
                                </label>
                              </>
                            )}
                          </div>
                          <label className="number"></label>
                        </div>
                      )
                    )}
                  </>
                ) : null}

                <div className="nobottom page-break">
                  <h3 className="common-heading">
                    5.1 Rainfall, Temperature and Ground Water Trend
                  </h3>
                  <div className="common-img-resolutions width-350 rainfalltrend">
                    <img src={baseUrl + regionData?.rainfallTrendChart} alt="crop Logo" />
                  </div>
                  <label className="figures">
                    Note: Normal rainfall is average value of last 50 years data
                  </label>
                  <label className="figures">
                    (Source: Indian Meteorological Department (IMD))
                  </label>
                  {regionData?.temperatureTrendChart ? (
                    <>
                      <div className="common-img-resolutions width-350 rainfalltrend">
                        <img src={baseUrl + regionData?.temperatureTrendChart} alt="crop Logo" />
                      </div>
                      <label className="figures">(Source:ECMWF Reanalysis v5 (ERA5))</label>
                    </>
                  ) : null}

                  <label className="number"></label>
                </div>

                <div className="nobottom page-break">
                  <div className="common-img-resolutions width-350 rainfalltrend">
                    <img src={baseUrl + regionData?.groundwaterTrendChart} alt="crop Logo" />
                  </div>
                  <label className="figures">
                    (Source: NASA Goddard Earth Sciences (GES) Data and Information Services Center
                    (DISC))
                  </label>
                  <label className="number"></label>
                </div>

                {/* <!-- Crop Health Monitoring (CHM) --> */}
                <div>
                  <div className="common-heading nobottom page-break">
                    {cropHealthMonitoring.length !== 0 ? (
                      <>
                        <h3 id="healthmonitoring" className="common-heading">
                          6. Crop Health Monitoring (CHM)
                        </h3>
                        <p className="common-para chm">
                          Field condition survey and crop health monitoring with various parameters
                          and Remote Sensing data was initiated with mobile based application
                          developed in house by RGI and conducted within each block starting from
                          <input
                            className="input-min-text chm"
                            type="text"
                            defaultValue={inputState?.chmDate ? inputState.chmDate : null}
                            name="chmDate"
                            onChange={debouncedOnChange}
                          />
                        </p>
                        <h3 className="common-heading">6.1 Ground Truthing Observation</h3>
                        <div className="common-img-sections">
                          <ul>
                            <li>
                              {/* <label className="figures">
                              Ground Truth Poins Map
                            </label> */}
                              <div className="common-img-resolutions ground_truth">
                                <img
                                  src={baseUrl + cropHealthMonitoring[0].img}
                                  alt="crop Health Logo"
                                />
                              </div>
                              <label className="figures">
                                {' '}
                                Exhibit : Block wise Distribution of Ground Truthing
                              </label>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : null}
                    <label className="number"></label>
                  </div>

                  {cropHealthMonitoring.length !== 0 ? (
                    <div className="common-img-sections page-break">
                      <ul>
                        <li className="page-break-after nobottom">
                          {/* <label className="figures">
                         
                          Ground Truth Poins Map
                        </label> */}
                          <div className="common-img-resolutions ground_truth">
                            <img
                              src={baseUrl + cropHealthMonitoring[1].img}
                              alt="crop Health Logo"
                            />
                          </div>
                          <label className="figures">
                            Exhibit : Block wise Ground Truthing of Crop Type
                          </label>
                          {/* <label className="figures">
                          Table for count of CHM points – Crop wise
                        </label> */}
                          <label className="number"></label>
                        </li>
                      </ul>
                    </div>
                  ) : null}

                  <div className="field-photographs">
                    <ImageUploading
                      multiple
                      value={cropPieChart}
                      onChange={onChangeCropPieChart}
                      maxNumber={cropPieMaxNumber}
                      dataURLKey="data_url">
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps
                      }) => (
                        // write your building UI
                        <>
                          <div className="feild-print">
                            {imageList.length === 0 && (
                              <h3 id="photographs" className="common-heading">
                                7. Crop Wise – Physical Appearance
                              </h3>
                            )}
                            <div className="upload-remove">
                              <Button
                                variant="contained"
                                style={isDragging ? { color: 'red' } : null}
                                onClick={onImageUpload}
                                {...dragProps}>
                                Upload Image
                              </Button>
                              &nbsp;
                              <Button variant="contained" onClick={onImageRemoveAll}>
                                Remove all images
                              </Button>
                            </div>
                          </div>
                          {cropPieChartChunkArray(imageList, 4).map((group, groupIndex) => (
                            <div className="upload__image-wrapper nobottom page-break">
                              {0 === groupIndex && (
                                <h3 className="common-heading">
                                  7. Crop Wise - Physical Appearance
                                </h3>
                              )}

                              <div className="field-imgs" key={groupIndex}>
                                {group.map((image, index) => (
                                  <>
                                    <div key={index} className="image-item">
                                      <div className="img-sections">
                                        <div className="upload-img">
                                          <img src={image.data_url} alt="" width="100" />
                                        </div>
                                        {/* <div className="img-overlay">
                                          <div className="image-item__btn-wrapper">
                                            <div
                                              className="hover-icon"
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                            >
                                              <img src={upload} alt="upload" />
                                            </div>
                                            <div
                                              className="hover-icon"
                                              onClick={() => {
                                                onImageRemove(index);
                                              }}
                                            >
                                              <img
                                                src={deleteIcon}
                                                alt="delete"
                                              />
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                      {/* <div className="text-area">
                                        <TextareaAutosize
                                          aria-label="empty textarea"
                                          maxLength="50"
                                          placeholder="Max 50 characters"
                                        />
                                      </div> */}
                                    </div>
                                  </>
                                ))}
                              </div>

                              {cropPieChartChunkArray(imageList, 4).length - 1 === groupIndex && (
                                <>
                                  <label className="figures">
                                    Exhibit : Crop Wise Condition Ground Truthing Observations
                                  </label>
                                </>
                              )}
                              <label className="number"></label>
                            </div>
                          ))}
                        </>
                      )}
                    </ImageUploading>
                  </div>

                  {ndviMap.length !== 0 ? (
                    <>
                      {ndviChunkArray(ndviMap, 6).map((group, groupIndex) => (
                        <div className="nobottom page-break">
                          {0 === groupIndex && (
                            <>
                              <h4 id="normalized" className="common-heading">
                                8. Normalized Difference Vegetation Index
                              </h4>
                              <div className="text-area">
                                <TextareaAutosize
                                  aria-label="empty textarea"
                                  maxLength="500"
                                  minRows={4}
                                  placeholder="Max 500 characters"
                                  defaultValue={inputState?.ndvi ? inputState?.ndvi : null}
                                  name="ndvi"
                                  onChange={debouncedOnChange}
                                />
                              </div>
                            </>
                          )}
                          <div className="common-img-sections">
                            <div key={groupIndex}>
                              <ul className="ndvi-images">
                                {group.map((item, index) => {
                                  var urlImg = baseUrl + item.img;
                                  return (
                                    <li className="ndvi_ndwi">
                                      <div className="common-img-resolutions weather">
                                        <img src={urlImg} alt="Satsure Logo" />
                                      </div>
                                      <label className="figures">
                                        {/* {item.label} */}
                                        {moment(item.label).format('DD-MM-YYYY')}
                                      </label>
                                    </li>
                                  );
                                })}
                              </ul>
                              <div className="common-img-resolutions ndi-img">
                                <img src={ndviMapLeg} alt="ndvi leg" />
                              </div>
                            </div>
                            {ndviChunkArray(ndviMap, 6).length - 1 === groupIndex && (
                              <>
                                <label className="figures">
                                  Exhibit : Time series variations of NDVI
                                  <br />
                                  Source: Sentinel 2 Satellite Data
                                </label>
                              </>
                            )}
                          </div>
                          <label className="number"></label>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {/* <!-- NDVI CHART SECTIONS --> */}
                  {ndviChartImage.length !== 0 ? (
                    <>
                      {ndviLineChartChunkArray(ndviChartImage, 1).map((group, groupIndex) => (
                        <div className="page-break nobottom">
                          <div className="common-img-sections">
                            {group.map((data, key) => {
                              var chartUrlImg = baseUrl + data?.chart;
                              var tableUrlImg = baseUrl + data?.table;
                              return (
                                <>
                                  <div className="common-img-resolutions ndvi-chart">
                                    <img src={chartUrlImg} alt="ndvi Logo" />
                                  </div>
                                  <div className="common-img-resolutions ndvi-chart">
                                    <img src={tableUrlImg} alt="ndvi Logo" />
                                  </div>
                                </>
                              );
                            })}
                          </div>
                          <label className="number"></label>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {/* <!-- NDWI IMAGE SECTIONS Normalized Difference Water Index--> */}
                  {ndwiMap.length !== 0 ? (
                    <>
                      {ndWiChunkArray(ndwiMap, 6).map((group, groupIndex) => (
                        <div className="nobottom page-break">
                          {0 === groupIndex && (
                            <>
                              <h4 id="waterindex" className="common-heading">
                                9. Normalized Difference Water Index
                              </h4>
                              <div className="text-area">
                                <TextareaAutosize
                                  aria-label="empty textarea"
                                  maxLength="500"
                                  minRows={4}
                                  placeholder="Max 500 characters"
                                  defaultValue={inputState?.ndwi ? inputState?.ndwi : null}
                                  name="ndwi"
                                  onChange={debouncedOnChange}
                                />
                              </div>
                            </>
                          )}
                          <div className="common-img-sections">
                            <div key={groupIndex}>
                              <ul className="ndwi-images">
                                {group.map((item) => {
                                  var urlImg = baseUrl + item.img;
                                  return (
                                    <li className="ndvi_ndwi">
                                      <div className="common-img-resolutions weather">
                                        <img src={urlImg} alt="ndwi Logo" />
                                      </div>
                                      <label className="figures">
                                        {/* {item.label} */}
                                        {moment(item.label).format('DD-MM-YYYY')}
                                      </label>
                                    </li>
                                  );
                                })}
                              </ul>
                              <div className="common-img-resolutions ndi-img">
                                <img src={ndwiMapLeg} alt="Ndvi Map Logo" />
                              </div>
                            </div>
                            {ndWiChunkArray(ndwiMap, 6).length - 1 === groupIndex && (
                              <>
                                <label className="figures">
                                  Exhibit : Time series variations of NDWI
                                  <br />
                                  Source: Sentinel 2 Satellite Data
                                </label>
                              </>
                            )}
                          </div>
                          <label className="number"></label>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {/* <!-- NDWI IMAGE SECTIONS --> */}
                  {ndwiChartImage.length !== 0 ? (
                    <>
                      {ndwiLineChartChunkArray(ndwiChartImage, 1).map((group, groupIndex) => (
                        <div className="page-break nobottom">
                          <div className="common-img-sections">
                            {group.map((data, key) => {
                              var chartUrlImg = baseUrl + data?.chart;
                              var tableUrlImg = baseUrl + data?.table;
                              return (
                                <>
                                  <div className="common-img-resolutions ndwi-chart">
                                    <img src={chartUrlImg} alt="ndvi chart Logo" />
                                  </div>
                                  <div className="common-img-resolutions ndwi-chart">
                                    <img src={tableUrlImg} alt="ndvi chart Logo" />
                                  </div>
                                </>
                              );
                            })}
                          </div>
                          <label className="number"></label>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>

                {/* <!-- Results and Discussion --> */}
                <div>
                  {cropSownArrMapImage.length !== 0 ? (
                    <>
                      <div className="nobottom page-break">
                        <h3 id="discussion" className="common-heading">
                          10. Crop Sowing
                        </h3>
                        <h5 className="common-min-heading">
                          10.1 Crop Sowing{" "}
                          <input
                            className="input-min-text"
                            type="text"
                            defaultValue={inputState?.cropsowing1 ? inputState?.cropsowing1 : null}
                            name="cropsowing1"
                            onChange={debouncedOnChange}
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Sown Map as on{' '}
                          {moment(cropSownArrMapImage[0].date).format('DD-MM-YYYY')}
                        </label>
                        <span className="sown_map_heading">
                          {/* Overall Sowing Percentage -{""} */}
                          <input
                            className="overall_input"
                            type="text"
                            defaultValue={
                              inputState?.cropsowingOverall1 ? inputState?.cropsowingOverall1 : null
                            }
                            
                            name="cropsowingOverall1"
                            onChange={debouncedOnChange}
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropSownArrMapImage[0].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>
                      <div className="nobottom page-break">
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sowing">
                            <img src={baseUrl + cropSownArrMapImage[0].chart} alt="crop Logo" />
                          </div>
                          <div className="common-img-resolutions crop-sowing table">
                            <img src={baseUrl + cropSownArrMapImage[0].table} alt="crop Logo" />
                          </div>
                          <label className="figures">
                            Exhibit : Crop sown map 1st update for
                            <span> {regionData?.regionDistrict}</span> district
                          </label>
                        </div>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            defaultValue={
                              inputState?.cropSownFirstUpdate
                                ? inputState?.cropSownFirstUpdate
                                : null
                            }
                            name="cropSownFirstUpdate"
                            onChange={debouncedOnChange}
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                  {cropSownArrMapImage.length !== 0 &&
                  cropSownArrMapImage[0].percentage_map !== '' ? (
                    <div className="page-break nobottom">
                      <div className="common-img-sections">
                        <div className="common-img-resolutions">
                          <img
                            src={baseUrl + cropSownArrMapImage[0].percentage_map}
                            alt="crop Logo"
                          />
                        </div>
                        <label className="figures">
                          Exhibit : Crop sown percentage map for
                          <span> {regionData?.regionDistrict}</span> district
                        </label>
                      </div>
                      <div className="text-area">
                        <TextareaAutosize
                          aria-label="empty textarea"
                          maxLength="500"
                          minRows={6}
                          placeholder="Max 500 characters"
                          defaultValue={
                            inputState.cropsownFirstPercentage
                              ? inputState.cropsownFirstPercentage
                              : null
                          }
                          name="cropsownFirstPercentage"
                          onChange={debouncedOnChange}
                        />
                      </div>
                      <label className="number"></label>
                    </div>
                  ) : null}

                  {cropSownArrMapImage.length !== 0 && cropSownArrMapImage.length !== 1 ? (
                    <>
                      <div className="page-break nobottom">
                        <h5 className="common-min-heading">
                          10.2 Crop Sowing
                          <input className="input-min-text" type="text" />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Sown Map as on {cropSownArrMapImage[1].date}
                        </label>
                        <span className="sown_map_heading">
                          {/* Overall Sowing Percentage -{" "} */}
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropsowing2"
                            style={{width:"300px"}}
                            defaultValue={inputState.cropsowing2 ? inputState.cropsowing2 : null}
                            onChange={debouncedOnChange}
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropSownArrMapImage[1].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>

                      <div className="page-break nobottom">
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sowing">
                            <img src={baseUrl + cropSownArrMapImage[1].chart} alt="crop Logo" />
                          </div>
                          <div className="common-img-resolutions crop-sowing table">
                            <img src={baseUrl + cropSownArrMapImage[1].table} alt="crop Logo" />
                          </div>
                          <label className="figures">
                            Exhibit : Crop sown Area 2nd update map for
                            <span> {regionData?.regionDistrict}</span> district
                          </label>
                        </div>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropsownSecondUpdate"
                            defaultValue={
                              inputState.cropsownSecondUpdate
                                ? inputState.cropsownSecondUpdate
                                : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                  {cropSownArrMapImage.length !== 0 &&
                  cropSownArrMapImage.length !== 1 &&
                  cropSownArrMapImage[1].percentage_map !== '' ? (
                    <div className="page-break nobottom">
                      <div className="common-img-sections">
                        <div className="common-img-resolutions">
                          <img
                            src={baseUrl + cropSownArrMapImage[1].percentage_map}
                            alt="crop Logo"
                          />
                        </div>
                        <label className="figures">
                          Exhibit : Crop sown percentage map for
                          <span> {regionData?.regionDistrict}</span> district
                        </label>
                      </div>
                      <div className="text-area">
                        <TextareaAutosize
                          aria-label="empty textarea"
                          maxLength="500"
                          minRows={6}
                          placeholder="Max 500 characters"
                          name="cropSownSecondPercentage"
                          defaultValue={
                            inputState?.cropSownSecondPercentage
                              ? inputState.cropSownSecondPercentage
                              : null
                          }
                          onChange={debouncedOnChange}
                        />
                      </div>
                      <label className="number"></label>
                    </div>
                  ) : null}

                  {cropSownArrMapImage.length !== 0 &&
                  cropSownArrMapImage.length !== 1 &&
                  cropSownArrMapImage.length !== 2 ? (
                    <>
                      <div className="page-break nobottom">
                        <h5 className="common-min-heading">
                          10.3 Crop Sowing
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropsowing3"
                            defaultValue={inputState?.cropSowing3 ? inputState?.cropSowing3 : null}
                            onChange={debouncedOnChange}
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Sown Map as on {cropSownArrMapImage[2].date}
                        </label>
                        <span className="sown_map_heading">
                          
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropsowingOverall3"
                            defaultValue={
                              inputState.cropsowingOverall3 ? inputState.cropsowingOverall3 : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropSownArrMapImage[2].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>
                      <div className="page-break nobottom">
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sowing">
                            <img src={baseUrl + cropSownArrMapImage[2].chart} alt="crop Logo" />
                          </div>
                          <div className="common-img-resolutions crop-sowing table">
                            <img src={baseUrl + cropSownArrMapImage[2].table} alt="crop Logo" />
                          </div>
                          <label className="figures">
                            Exhibit : Crop sown Area 3rd update map for
                            <span> {regionData?.regionDistrict}</span> district
                          </label>
                        </div>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropsownThirdUpdate"
                            defaultValue={
                              inputState?.cropsownThirdUpdate
                                ? inputState?.cropsownThirdUpdate
                                : null
                            }
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                  {cropSownArrMapImage.length !== 0 &&
                  cropSownArrMapImage.length !== 1 &&
                  cropSownArrMapImage[0].percentage_map !== '' &&
                  cropSownArrMapImage.length !== 2 ? (
                    <div className="page-break nobottom">
                      <div className="common-img-sections">
                        <div className="common-img-resolutions">
                          <img
                            src={baseUrl + cropSownArrMapImage[2].percentage_map}
                            alt="crop Logo"
                          />
                        </div>
                        <label className="figures">
                          Exhibit : Crop sown percentage map for
                          <span> {regionData?.regionDistrict}</span> district
                        </label>
                      </div>
                      <div className="text-area">
                        <TextareaAutosize
                          aria-label="empty textarea"
                          maxLength="500"
                          minRows={6}
                          placeholder="Max 500 characters"
                          name="cropSownThirdPercentage"
                          defaultValue={
                            inputState?.cropSownThirdPercentage
                              ? inputState.cropSownThirdPercentage
                              : null
                          }
                          onChange={debouncedOnChange}
                        />
                      </div>
                      <label className="number"></label>
                    </div>
                  ) : null}

                  {cropAcreageMapImage.length !== 0 ? (
                    <>
                      <div className="nobottom page-break">
                        <h3 id="discussion" className="common-heading">
                          11. Crop Acreage
                        </h3>
                        <h5 className="common-min-heading">
                          11.1 Crop Acreage{" "}
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropAcreage1"
                            defaultValue={inputState.cropAcreage1 ? inputState.cropAcreage1 : null}
                            onChange={debouncedOnChange}
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Acreage Map as on {cropAcreageMapImage[0].date}
                        </label>
                        <span className="sown_map_heading">
                         
                          <input
                            className="overall_input"
                            type="text"
                            name="cropAcreageOverall1"
                            
                            defaultValue={
                              inputState.cropAcreageOverall1 ? inputState.cropAcreageOverall1 : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropAcreageMapImage[0].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>

                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropAcreageMapImage[0].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropAcreageMapImage[0].table} alt="crop Logo" />
                        </div>
                        <label className="figures">
                          Exhibit : Crop Acreage map for <span>{regionData?.regionDistrict} </span>
                          district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropAcreageMap"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState.cropAcreageMap ? inputState.cropAcreageMap : null
                            }
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                  {cropAcreageMapImage.length !== 0 && cropAcreageMapImage.length !== 1 ? (
                    <>
                      <div className="nobottom page-break">
                        <h5 className="common-min-heading">
                          11.2 Crop Acreage{" "}
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropAcreage2"
                            defaultValue={
                              inputState?.cropAcreage2 ? inputState?.cropAcreage2 : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Acreage Map as on {cropAcreageMapImage[1].date}
                        </label>
                        <span className="sown_map_heading">
                          
                          <input
                            className="overall_input"
                            type="text"
                            name="cropAcreageOverall2"
                          
                            defaultValue={
                              inputState?.cropAcreageOverall2
                                ? inputState?.cropAcreageOverall2
                                : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropAcreageMapImage[1].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>

                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropAcreageMapImage[1].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropAcreageMapImage[1].table} alt="crop Logo" />
                        </div>

                        <label className="figures">
                          Exhibit : Crop Acreage map for <span>{regionData?.regionDistrict} </span>
                          district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropAcreageMap2"
                            defaultValue={
                              inputState?.cropAcreageMap2 ? inputState?.cropAcreageMap2 : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                {cropAcreageMapImage.length !== 0 &&
                  cropAcreageMapImage.length !== 1 &&
                  cropAcreageMapImage.length !== 2 ? (
                    <>
                      <div className="nobottom page-break">
                        <h5 className="common-min-heading">
                          11.3 Crop Acreage{" "}
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropAcreage3"
                            
                            defaultValue={
                              inputState?.cropAcreage3 ? inputState?.cropAcreage3 : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Acreage Map as on {cropAcreageMapImage[2].date}
                        </label>
                        <span className="sown_map_heading">
                         
                          <input
                           className="overall_input"
                            type="text"
                            name="cropAcreageOverall3"
                          
                            defaultValue={
                              inputState?.cropAcreageOverall3
                                ? inputState?.cropAcreageOverall3
                                : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropAcreageMapImage[2].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>
                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropAcreageMapImage[2].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropAcreageMapImage[2].table} alt="crop Logo" />
                        </div>
                        <label className="figures">
                          Exhibit : Crop Acreage map for <span>{regionData?.regionDistrict} </span>
                          district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropAcreageMap3"
                            defaultValue={
                              inputState?.cropAcreageMap3 ? inputState?.cropAcreageMap3 : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null} 

                  {cropAcreageMapImage.length !== 0 &&
                  cropAcreageMapImage.length !== 1 &&
                  cropAcreageMapImage.length !== 2 &&
                  cropAcreageMapImage.length !== 3 ? (
                    <>
                      <div className="nobottom page-break">
                        <h5 className="common-min-heading">
                          11.4 Crop Acreage{" "}
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropAcreage4"
                            defaultValue={
                              inputState?.cropAcreage4 ? inputState?.cropAcreage4 : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Acreage Map as on {cropAcreageMapImage[3].date}
                        </label>
                        <span className="sown_map_heading">
                          
                          <input
                             className="overall_input"
                            type="text"
                            name="cropAcreageOverall4"
                           
                            defaultValue={
                              inputState?.cropAcreageOverall4
                                ? inputState?.cropAcreageOverall4
                                : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropAcreageMapImage[3].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>
                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropAcreageMapImage[3].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropAcreageMapImage[3].table} alt="crop Logo" />
                        </div>
                        <label className="figures">
                          Exhibit : Crop Acreage map for <span>{regionData?.regionDistrict} </span>
                          district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropAcreageMap4"
                            defaultValue={
                              inputState?.cropAcreageMap4 ? inputState?.cropAcreageMap4 : null
                            }
                            onChange={debouncedOnChange}
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null} 

                   {cropPerformanceImage.length !== 0 ? (
                    <div className="nobottom">
                      <h5 className="common-min-heading page-break">7.1.6 Crop Performance</h5>
                      <div className="common-img-sections">
                        <ul className="crop-list">
                          {cropPerformanceImage.map((item) => {
                            var urlImg = baseUrl + item;
                           
                            return (
                              <li>
                                <div>
                                  <div className="common-img-resolutions">
                                    <img src={urlImg} alt="Crop Logo" />
                                  </div>
                                  <label className="figures">{item.label}</label>
                                </div>
                              </li>
                            );
                          })}
                        </ul>

                        <label className="figures">
                          Exhibit : Crop Performance map for
                          <span> {regionData?.regionDistrict} </span> district
                        </label>
                      </div>
                      <div className="text-area">
                        <TextareaAutosize
                          aria-label="empty textarea"
                          maxLength="500"
                          minRows={6}
                          placeholder="Max 500 characters"
                          name="cropPerformance"
                          onChange={debouncedOnChange}
                          defaultValue={
                            inputState?.cropPerformance ? inputState?.cropPerformance : null
                          }
                        />
                      </div>
                      <label className="number"></label>
                    </div>
                  ) : null} 

                  {cropHarvestMapImage.length !== 0 ? (
                    <>
                      <div className="nobottom page-break">
                        <h3 id="discussion" className="common-heading">
                          12. Crop Harvest 1 
                        </h3>
                        <h5 className="common-min-heading">
                          12.1 Crop Harvest 1{' '}
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropHarvest1"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvest1 ? inputState?.cropHarvest1 : null
                            }
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Harvest Map as on {cropHarvestMapImage[0].date}
                        </label>
                        <span className="sown_map_heading">
                         
                          <input
                            className="overall_input"
                            type="text"
                            name="cropHarvestOverall1"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestOverall1
                                ? inputState?.cropHarvestOverall1
                                : null
                            }
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropHarvestMapImage[0].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>

                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropHarvestMapImage[0].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropHarvestMapImage[0].table} alt="crop Logo" />
                        </div>
                        <label className="figures">
                          Exhibit : Crop Harvest 1st update for
                          <span> {regionData?.regionDistrict} </span>district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropHarvestFirstUpdate"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestFirstUpdate
                                ? inputState?.cropHarvestFirstUpdate
                                : null
                            }
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                 {cropHarvestMapImage.length !== 0 && cropHarvestMapImage.length !== 1 ? (
                    <>
                      <div className="nobottom page-break">
                        <h5 className="common-min-heading">
                          12.2 Crop Harvest 1 {' '}
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropHarvest2"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvest2 ? inputState?.cropHarvest2 : null
                            }
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Harvest Map as on {cropHarvestMapImage[1].date}
                        </label>
                        <span className="sown_map_heading">
                          
                          <input
                            className="overall_input"
                            type="text"
                            name="cropHarvestOverall2"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestOverall2
                                ? inputState?.cropHarvestOverall2
                                : null
                            }
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropHarvestMapImage[1].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>
                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropHarvestMapImage[1].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropHarvestMapImage[1].table} alt="crop Logo" />
                        </div>
                        <label className="figures">
                          Exhibit : Crop harvest 1st update for
                          <span> {regionData?.regionDistrict}</span> district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropHarvestSecondUpdate"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestSecondUpdate
                                ? inputState?.cropHarvestSecondUpdate
                                : null
                            }
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null} 
                  {cropHarvestMapImage.length !== 0 &&
                  cropHarvestMapImage.length !== 1 &&
                  cropHarvestMapImage.length !== 2 ? (
                    <>
                      <div className="nobottom page-break">
                        <h5 className="common-min-heading">
                          12.3 Crop Harvest 1{" "}
                          <input
                            className="overall_input"
                            type="text"
                            name="cropHarvest3"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvest3 ? inputState?.cropHarvest3 : null
                            }
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Harvest Map as on {cropHarvestMapImage[2].date}
                        </label>
                        <span className="sown_map_heading">
                         
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropHarvestOverall3"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestOverall3
                                ? inputState?.cropHarvestOverall3
                                : null
                            }
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropHarvestMapImage[2].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>
                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropHarvestMapImage[2].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropHarvestMapImage[2].table} alt="crop Logo" />
                        </div>
                        <label className="figures">
                          Exhibit : Crop harvest 1st update for
                          <span> {regionData?.regionDistrict}</span> district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropHarvestThirdUpdate"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestThirdUpdate
                                ? inputState?.cropHarvestThirdUpdate
                                : null
                            }
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                  {cropHarvestMapImage.length !== 0 &&
                  cropHarvestMapImage.length !== 1 &&
                  cropHarvestMapImage.length !== 2 &&
                  cropHarvestMapImage.length !== 3 ? (
                    <>
                      <div className="nobottom page-break">
                        <h5 className="common-min-heading">
                         12.4 Crop Harvest 1{" "}
                          <input
                           className="overall_input"
                            type="text"
                            name="cropHarvest4"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvest4 ? inputState?.cropHarvest4 : null
                            }
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Harvest Map as on {cropHarvestMapImage[3].date}
                        </label>
                        <span className="sown_map_heading">
                         
                          <input
                            className="overall_input"
                            type="text"
                            name="cropHarvestOverall4"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestOverall4
                                ? inputState?.cropHarvestOverall4
                                : null
                            }
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropHarvestMapImage[3].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>
                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropHarvestMapImage[3].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropHarvestMapImage[3].table} alt="crop Logo" />
                        </div>
                        <label className="figures">
                          Exhibit : Crop harvest 1st update for
                          <span> {regionData?.regionDistrict}</span> district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropHarvestfourthUpdate"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestfourthUpdate
                                ? inputState?.cropHarvestfourthUpdate
                                : null
                            }
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                  {cropHarvestMapImage.length !== 0 &&
                  cropHarvestMapImage.length !== 1 &&
                  cropHarvestMapImage.length !== 2 &&
                  cropHarvestMapImage.length !== 3 &&
                  cropHarvestMapImage.length !== 4 ? (
                    <>
                      <div className="nobottom page-break">
                        <h5 className="common-min-heading">
                          12.5 Crop Harvest 1{" "}
                          <input
                            className="overall_input"
                            type="text"
                            name="cropHarvest5"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvest5 ? inputState?.cropHarvest5 : null
                            }
                          />
                        </h5>
                        <label className="sown_map_heading">
                          Crop Harvest Map as on {cropHarvestMapImage[4].date}
                        </label>
                        <span className="sown_map_heading">
                         
                          <input
                            className="input-min-text"
                            type="text"
                            name="cropHarvestOverall5"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestOverall5
                                ? inputState?.cropHarvestOverall5
                                : null
                            }
                          />
                        </span>
                        <div className="common-img-sections">
                          <div className="common-img-resolutions crop-sown-map-2">
                            <img src={baseUrl + cropHarvestMapImage[4].map} alt="crop Logo" />
                          </div>
                        </div>
                        <label className="number"></label>
                      </div>
                      <div className="nobottom page-break">
                        <div className="common-img-resolutions crop-sowing">
                          <img src={baseUrl + cropHarvestMapImage[4].chart} alt="crop Logo" />
                        </div>
                        <div className="common-img-resolutions crop-sowing table">
                          <img src={baseUrl + cropHarvestMapImage[4].table} alt="crop Logo" />
                        </div>
                        <label className="figures">
                          Exhibit : Crop harvest 1st update for
                          <span> {regionData?.regionDistrict}</span> district
                        </label>
                        <div className="text-area">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            maxLength="500"
                            minRows={6}
                            placeholder="Max 500 characters"
                            name="cropHarvestfifthUpdate"
                            onChange={debouncedOnChange}
                            defaultValue={
                              inputState?.cropHarvestfifthUpdate
                                ? inputState?.cropHarvestfifthUpdate
                                : null
                            }
                          />
                        </div>
                        <label className="number"></label>
                      </div>
                    </>
                  ) : null}

                  {/* {isCropHarvest2Included && (
                    <>
                      {cropHarvestMapImage2?.length !== 0 ? (
                        <>
                          <div className="nobottom page-break">
                            <h3 id="discussion" className="common-heading">
                              13. Crop Harvest 2
                            </h3>
                            <h5 className="common-min-heading">
                              13.1 Crop Harvest 2
                              <input
                                className="input-min-text"
                                type="text"
                                name="crop_harvest1"
                                onChange={debouncedOnChange}
                                defaultValue={inputState?.crop_harvest1 || null}
                              />
                            </h5>
                            <label className="sown_map_heading">
                              Crop Harvest Map as on {cropHarvestMapImage2[0]?.date}
                            </label>
                            <span className="sown_map_heading">
                            
                              <input
                                className="input-min-text"
                                type="text"
                                name="crop_harvest_overall1"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.crop_harvest_overall1
                                    ? inputState?.crop_harvest_overall1
                                    : null
                                }
                              />
                            </span>
                            <div className="common-img-sections">
                              <div className="common-img-resolutions crop-sown-map-2">
                                <img src={baseUrl + cropHarvestMapImage2[0]?.map} alt="crop Logo" />
                              </div>
                            </div>
                            <label className="number"></label>
                          </div>

                          <div className="nobottom page-break">
                            <div className="common-img-resolutions crop-sown-map">
                              <img src={baseUrl + cropHarvestMapImage2[0].chart} alt="crop Logo" />
                            </div>
                            <div className="common-img-resolutions crop-sown-map">
                              <img src={baseUrl + cropHarvestMapImage2[0].table} alt="crop Logo" />
                            </div>
                            <label className="figures">
                              Exhibit : Crop Harvest 2nd update for
                              <span> {regionData?.regionDistrict} </span>district
                            </label>
                            <div className="text-area">
                              <TextareaAutosize
                                aria-label="empty textarea"
                                maxLength="500"
                                placeholder="Max 500 characters"
                                name="crop_harvest_first_update"
                                onChange={debouncedOnChange}
                                defaultValue={inputState?.crop_harvest_first_update || null}
                              />
                            </div>
                            <label className="number"></label>
                          </div>
                        </>
                      ) : null}

                      {cropHarvestMapImage2?.length !== 0 && cropHarvestMapImage2?.length !== 1 ? (
                        <>
                          <div className="nobottom page-break">
                            <h5 className="common-min-heading">
                              13.2 Crop Harvest 2
                              <input
                                className="input-min-text"
                                type="text"
                                name="crop_harvest2"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.crop_harvest2 ? inputState?.crop_harvest2 : null
                                }
                              />
                            </h5>
                            <label className="sown_map_heading">
                              Crop Harvest Map as on {cropHarvestMapImage2[1].date}
                            </label>
                            <span className="sown_map_heading">
                           
                              <input
                                className="input-min-text"
                                type="text"
                                name="crop_harvest_overall2"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.crop_harvest_overall2
                                    ? inputState?.crop_harvest_overall2
                                    : null
                                }
                              />
                            </span>
                            <div className="common-img-sections">
                              <div className="common-img-resolutions crop-sown-map-2">
                                <img src={baseUrl + cropHarvestMapImage2[1].map} alt="crop Logo" />
                              </div>
                            </div>
                            <label className="number"></label>
                          </div>
                          <div className="nobottom page-break">
                            <div className="common-img-resolutions crop-sown-map">
                              <img src={baseUrl + cropHarvestMapImage2[1].chart} alt="crop Logo" />
                            </div>
                            <div className="common-img-resolutions crop-sown-map">
                              <img src={baseUrl + cropHarvestMapImage2[1].table} alt="crop Logo" />
                            </div>
                            <label className="figures">
                              Exhibit : Crop harvest 2nd update for
                              <span> {regionData?.regionDistrict}</span> district
                            </label>
                            <div className="text-area">
                              <TextareaAutosize
                                aria-label="empty textarea"
                                maxLength="500"
                                placeholder="Max 500 characters"
                                name="crop_harvest_second_update"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.crop_harvest_second_update
                                    ? inputState?.crop_harvest_second_update
                                    : null
                                }
                              />
                            </div>
                            <label className="number"></label>
                          </div>
                        </>
                      ) : null}

                      {cropHarvestMapImage2?.length !== 0 &&
                      cropHarvestMapImage2?.length !== 1 &&
                      cropHarvestMapImage2?.length !== 2 ? (
                        <>
                          <div className="nobottom page-break">
                            <h5 className="common-min-heading">
                              13.3 Crop Harvest 2
                              <input
                                className="input-min-text"
                                type="text"
                                name="crop_harvest3"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.crop_harvest3 ? inputState?.crop_harvest3 : null
                                }
                              />
                            </h5>
                            <label className="sown_map_heading">
                              Crop Harvest Map as on
                              {cropHarvestMapImage2[2].date}
                            </label>
                            <span className="sown_map_heading">
                             
                              <input
                                className="input-min-text"
                                type="text"
                                name="crop_harvest_overall3"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.crop_harvest_overall3
                                    ? inputState?.crop_harvest_overall3
                                    : null
                                }
                              />
                            </span>
                            <div className="common-img-sections">
                              <div className="common-img-resolutions crop-sown-map-2">
                                <img src={baseUrl + cropHarvestMapImage2[2].map} alt="crop Logo" />
                              </div>
                            </div>
                            <label className="number"></label>
                          </div>
                          <div className="nobottom page-break">
                            <div className="common-img-resolutions crop-sown-map">
                              <img src={baseUrl + cropHarvestMapImage2[2].chart} alt="crop Logo" />
                            </div>
                            <div className="common-img-resolutions crop-sown-map">
                              <img src={baseUrl + cropHarvestMapImage2[2].table} alt="crop Logo" />
                            </div>
                            <label className="figures">
                              Exhibit : Crop harvest 2nd update for
                              <span> {regionData?.regionDistrict}</span> district
                            </label>
                            <div className="text-area">
                              <TextareaAutosize
                                aria-label="empty textarea"
                                maxLength="500"
                                placeholder="Max 500 characters"
                                name="crop_harvest_third_update"
                                onChange={debouncedOnChange}
                                defaultValue={
                                  inputState?.crop_harvest_third_update
                                    ? inputState?.crop_harvest_third_update
                                    : null
                                }
                              />
                            </div>
                            <label className="number"></label>
                          </div>
                        </>
                      ) : null}
                    </>
                  )} */}
                </div>

                <div className="field-photographs">
                
                  <ImageUploading
                    multiple
                    value={fieldPhotosPhase1}
                    onChange={onHandlePhase1}
                    maxNumber={40}
                    dataURLKey="data_url">
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                    }) => (
                      // write your building UI
                      <>
                        <div className="feild-print">
                          {imageList.length === 0 && (
                            <>
                              <h3 id="photographs" className="common-heading">
                                13. Field Photographs
                              </h3>

                              <h3 id="photographs" className="common-min-heading">
                                13.1 Phase 1
                              </h3>
                            </>
                          )}
                          <div className="upload-remove">
                            <Button
                              variant="contained"
                              style={isDragging ? { color: 'red' } : null}
                              onClick={onImageUpload}
                              {...dragProps}>
                              Upload Image
                            </Button>

                            <Button variant="contained" onClick={onImageRemoveAll}>
                              Remove all images
                            </Button>
                          </div>
                        </div>
                        {cropPieChartChunkArray(imageList, 4).map((group, groupIndex) => (
                          <div className="upload__image-wrapper nobottom page-break">
                            {0 === groupIndex && (
                              <>
                                <h3 id="photographs" className="common-heading">
                                 13. Field Photographs 
                                </h3>
                                <h3 className="common-min-heading">
                                 13.1 Phase 1
                                </h3>
                              </>
                            )}

                            <div className="field-imgs" key={groupIndex}>
                              {group.map((image, index) => {
                                return (
                                  <div key={index} className="image-item">
                                    <div className="img-sections" id={image.id}>
                                      <div className="upload-img">
                                        <img src={image.data_url} alt="" width="100" />
                                      </div>
                                    </div>
                                    <div className="text-area">
                                      <TextareaAutosize
                                        aria-label="empty textarea"
                                        maxLength="50"
                                        className="crop-name"
                                        defaultValue={
                                          inputState?.[image.id] ? inputState[image.id] : null
                                        }
                                        placeholder="Max 50 characters"
                                        name={image.id}
                                        onChange={debouncedOnChange}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            {cropPieChartChunkArray(imageList, 4).length - 1 === groupIndex && (
                              <>
                                <label className="figures">
                                  Exhibit : Crop Wise Condition Ground Truthing Observations
                                </label>
                              </>
                            )}
                            <label className="number"></label>
                          </div>
                        ))}
                      </>
                    )}
                  </ImageUploading>

                
                  <ImageUploading
                    multiple
                    value={fieldPhotosPhase2}
                    onChange={onHandlePhase2}
                    maxNumber={40}
                    dataURLKey="data_url">
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                    }) => (
                      // write your building UI
                      <>
                        <div className="feild-print">
                          {imageList.length === 0 && (
                            <h3 id="photographs" className="common-min-heading">
                              13.2 Phase 2
                            </h3>
                          )}
                          <div className="upload-remove">
                            <Button
                              variant="contained"
                              style={isDragging ? { color: 'red' } : null}
                              onClick={onImageUpload}
                              {...dragProps}>
                              Upload Image
                            </Button>

                            <Button variant="contained" onClick={onImageRemoveAll}>
                              Remove all images
                            </Button>
                          </div>
                        </div>
                        {cropPieChartChunkArray(imageList, 4).map((group, groupIndex) => (
                          <div className="upload__image-wrapper nobottom page-break">
                            {0 === groupIndex && (
                              <h3 className="common-min-heading">
                                13.2 Phase 2 
                              </h3>
                            )}

                            <div className="field-imgs" key={groupIndex}>
                              {group.map((image, index) => {
                                return (
                                  <div key={index} className="image-item">
                                    <div className="img-sections" id={image.id}>
                                      <div className="upload-img">
                                        <img src={image.data_url} alt="" width="100" />
                                      </div>
                                    </div>
                                    <div className="text-area">
                                      <TextareaAutosize
                                        aria-label="empty textarea"
                                        maxLength="50"
                                        className="crop-name"
                                        defaultValue={
                                          inputState?.[image.id] ? inputState[image.id] : null
                                        }
                                        placeholder="Max 50 characters"
                                        name={image.id}
                                        onChange={debouncedOnChange}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            {cropPieChartChunkArray(imageList, 4).length - 1 === groupIndex && (
                              <>
                                <label className="figures">
                                  Exhibit : Crop Wise Condition Ground Truthing Observations
                                </label>
                              </>
                            )}
                            <label className="number"></label>
                          </div>
                        ))}
                      </>
                    )}
                  </ImageUploading>

                  
                   <ImageUploading
                    multiple
                    value={fieldPhotosPhase3}
                    onChange={onHandlePhase3}
                    maxNumber={40}
                    dataURLKey="data_url">
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                    }) => (
                      // write your building UI
                      <>
                        <div className="feild-print">
                          {imageList.length === 0 && (
                            <h3 id="photographs" className="common-min-heading">
                             13.3 Phase 3
                            </h3>
                          )}
                          <div className="upload-remove">
                            <Button
                              variant="contained"
                              style={isDragging ? { color: 'red' } : null}
                              onClick={onImageUpload}
                              {...dragProps}>
                              Upload Image
                            </Button>

                            <Button variant="contained" onClick={onImageRemoveAll}>
                              Remove all images
                            </Button>
                          </div>
                        </div>
                        {cropPieChartChunkArray(imageList, 4).map((group, groupIndex) => (
                          <div className="upload__image-wrapper nobottom page-break">
                            {0 === groupIndex && (
                              <h3 className="common-min-heading">
                               13.3 Phase 3
                              </h3>
                            )}

                            <div className="field-imgs" key={groupIndex}>
                              {group.map((image, index) => {
                                return (
                                  <div key={index} className="image-item">
                                    <div className="img-sections" id={image.id}>
                                      <div className="upload-img">
                                        <img src={image.data_url} alt="" width="100" />
                                      </div>
                                    </div>
                                    <div className="text-area">
                                      <TextareaAutosize
                                        aria-label="empty textarea"
                                        maxLength="50"
                                        className="crop-name"
                                        defaultValue={
                                          inputState?.[image.id] ? inputState[image.id] : null
                                        }
                                        placeholder="Max 50 characters"
                                        name={image.id}
                                        onChange={debouncedOnChange}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            {cropPieChartChunkArray(imageList, 4).length - 1 === groupIndex && (
                              <>
                                <label className="figures">
                                  Exhibit : Crop Wise Condition Ground Truthing Observations
                                </label>
                              </>
                            )}
                            <label className="number"></label>
                          </div>
                        ))}
                      </>
                    )}
                  </ImageUploading>
                </div>

                {/* <!-- Conclusion --> */}
                <div className="nobottom ">
                  <h3 id="conclusion" className="page-break common-heading">
                   14. Conclusion
                  </h3>
                  <div className="text-area">
                    <TextareaAutosize
                      aria-label="empty textarea"
                      maxLength="2000"
                      minRows={20}
                      placeholder="Max 2000 characters"
                      name="conclusion"
                      onChange={debouncedOnChange}
                      defaultValue={inputState?.conclusion ? inputState?.conclusion : null}
                    />
                  </div>
                  {cropCalendarImage.length !== 0 ? (
                    <div>
                      <label className="figures">Crop Calendar</label>
                      <div className="common-img-sections crop-cal">
                        {cropCalendarImage.map((data, key) => {
                          var urlImg = baseUrl + data;
                          return (
                            <div className="common-img-resolutions">
                              <img src={urlImg} alt="crop Logo" />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  <label className="number"></label>
                </div>

                <div className="nobottom page-break">
                  <div className="annexure">
                    <label className="common-heading">
                     15. Annexure
                    </label>
                    <div className="annexure-table">
                      <table>
                        <thead>
                          <tr>
                            <th className="abbreviations" colSpan="5">
                              List of Abbreviations
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>GIS</td>
                            <td>Geographic Information System</td>
                            <td rowSpan="6"></td>
                            <td>NDWI</td>
                            <td>Normalized Difference Water Index</td>
                          </tr>
                          <tr>
                            <td>CHM</td>
                            <td>Crop Health Monitoring</td>
                            <td>NIR</td>
                            <td>Near Infrared Radiation</td>
                          </tr>
                          <tr>
                            <td>IMD</td>
                            <td>India Meteorological Department</td>
                            <td>NRSC</td>
                            <td>National Remote Sensing Centre</td>
                          </tr>
                          <tr>
                            <td>LPA</td>
                            <td>Long Period Average</td>
                            <td>GPM</td>
                            <td>Global Precipitation Measurement</td>
                          </tr>
                          <tr>
                            <td>MNCFC</td>
                            <td>Mahalanobis National Crop Forecast Centre</td>
                            <td>RGICL</td>
                            <td>Reliance General Insurance Company Limited</td>
                          </tr>
                          <tr>
                            <td>NDVI</td>
                            <td>Normalized Difference Vegetation Index</td>
                            <td>SWIR</td>
                            <td>Short-wave Infrared Radiation</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h4 className="common-sub-heading">Data Products Used</h4>
                    <p className="common-para">
                      Sentinel-2 is an Earth observation mission from the Copernicus Programme that
                      systematically acquires optical imagery at high spatial resolution (10 m to 60
                      m) over land and coastal waters. The Sentinel-2 satellites each carry a single
                      multi-spectral instrument (MSI) with 13 spectral channels in the visible/near
                      infrared (VNIR) and short wave infrared spectral range (SWIR). The satellite
                      revisits for every 5 - 6 days under the same viewing angles. At high
                      latitudes, Sentinel-2 swath overlap and some regions will be observed twice or
                      more every 5 - 6 days, but with different viewing angles. The spatial
                      resolution of Red, Green, Blue and Near Infrared bands of Sentinel -2 data is
                      10 meters. The mission provides information for agricultural and forestry
                      practices and for helping manage food security. Satellite images will be used
                      to determine various plant indices such as leaf area chlorophyll and water
                      content indexes. This is particularly important for effective yield prediction
                      and applications related to Earth's vegetation.
                    </p>
                  </div>
                  <p className="common-para">
                    Sentinel-1 is the first of the Copernicus Programme satellite constellation
                    conducted by the European Space Agency. This mission is composed of a
                    constellation of two satellites, Sentinel-1A and Sentinel-1B, which share the
                    same orbital plane. They carry a C-band synthetic-aperture radar instrument
                    which provides a collection of data in all-weather, day or night. This
                    instrument has a spatial resolution of down to 5m - 20m and a swath of up to 400
                    km. The Sentinel Data is used to derive the flood inundation area and analyze
                    the crop damage area.
                  </p>
                  <h5 className="common-sub-heading">
                    Normalized Difference Vegetation Index (NDVI)
                  </h5>
                  <p className="common-para">
                    NDVI is calculated from the Visible (Red) and Near-Infrared light reflected by
                    vegetation. Healthy vegetation absorbs most of the Visible Light that hits it,
                    and reflects a large portion of the Near-Infrared light. Unhealthy or sparse
                    vegetation reflects more visible light and less Near-Infrared light. Nearly all
                    satellite Vegetation Indices employ this difference formula to quantify the
                    density of plant growth on the Earth Near-Infrared Radiation (NIR) minus a part
                    of Visible radiation (RED) divided by Near-Infrared radiation (NIR) plus a part
                    of visible radiation (RED). The result of this formula is called the Normalized
                    Difference Vegetation Index (NDVI). The NDVI is mathematically written as,
                  </p>
                  <label className="formula">NDVI = (NIR - RED) / (NIR + RED)</label>
                  <p className="common-para">
                    For a given pixel, NDVI always result in a number that ranges from minus one
                    (-1) to plus one (+1). No green leaves give a value close to zero. A zero means
                    no vegetation, and close to +1 (0.8 - 0.9) indicates the highest possible
                    density of green leaves.
                  </p>
                  <div className="common-img-resolutions ndi-img">
                    <img src={ndviMapLeg} alt="ndvi leg" />
                  </div>

                  <label className="number"></label>
                </div>

                <div className="nobottom page-break">
                  <h5 className="common-sub-heading">Normalized Difference Water Index (NDWI)</h5>
                  <p className="common-para">
                    Normalized Difference Water Index (NDWI) is used to refer to either one of two
                    remote sensing derived indexes related to liquid water. In this analysis, the
                    NDWI is used in the context of monitoring water content of leaves, using NIR and
                    Short-wave Infrared (SWIR) wavelengths. The SWIR reflectance is a proxy for
                    changes in both vegetation water content and the spongy mesophyll structure in
                    vegetation canopies. The NIR reflectance is however, affected by leaf internal
                    structure and leaf dry matter content. The combination of the NIR with the SWIR
                    removes variations induced by leaf internal structure and leaf dry matter
                    content. Mathematically NDWI is represented as
                  </p>
                  <label className="formula">NDWI = (NIR - SWIR) / (NIR + SWIR)</label>
                  <p className="common-para">
                    For a given pixel, NDWI always result in a number ranging from minus one (-1) to
                    plus one (+1). Values from -1 to 0 indicates a bright surface with no vegetation
                    or water content, while closer to +1 indicates presence of water.
                  </p>
                  <div className="common-img-resolutions ndi-img">
                    <img src={ndwiMapLeg} alt="Ndvi Map Logo" />
                  </div>
                  <div className="common-img-resolutions crop-growth">
                    <img src={cropGrowth} alt="crop Logo" />
                  </div>
                  <h4 id="cropsowing" className="common-sub-heading">
                    Crop Sowing
                  </h4>
                  <div className="common-img-sections">
                    <p className="common-para">
                      Crop Sowing means the process of planting and the area of sowing is termed as
                      crop sown area. Crop sown area gives us the initial forecast of the crop
                      productivity in a desired area. The Remote Sensing methodology for estimating
                      the crop sowing is shown in Fig.3
                    </p>
                    <div className="common-img-resolutions acreage_sowing">
                      <img src={cropLogo} alt="Crop Logo" />
                    </div>
                  </div>{' '}
                  <label className="number"></label>
                </div>

                <div className="nobottom page-break">
                  <h4 id="cropacreage" className="common-sub-heading">
                    Crop Acreage
                  </h4>
                  <p className="common-para">
                    Crop Acreage indicates the area of a specific crop planted in a cropping season.
                    Different crops are identified based on NDVI time series, which are unique to
                    each crop. The Image classification is carried out, and Crop Acreage is derived
                    using the following equation:
                  </p>
                  <label className="formula">Acreage = No. of Pixels × Pixel Area/10000</label>

                  <p className="common-para">
                    Where – Number of Pixels derived from the image classification for a crop; Pixel
                    Area is the area covered by the pixel at the lowest resolution; Acreage is in
                    Hectares (Ha)
                  </p>
                  <p className="common-para">
                    The Remote Sensing methodology for estimation of Crop Acreage is shown
                  </p>
                  <div className="common-img-resolutions acreage_sowing">
                    <img src={cropAcreageLogo} alt="Crop Acerage" />
                  </div>
                  <h4 id="cropperformance" className="common-sub-heading">
                    Crop Performance
                  </h4>
                  <p className="common-para">
                    Relative yield is a measure to assess the performance of the crop for the season
                    relative to each district. The crop performance is divided into following
                    categories: Below average, Average, Good and Very good. It is the signal
                    indicator to check in which area of the district, the crop has performed better
                    in comparison with compared with other parts of the district. Relative yield of
                    the crop is calculated using the vegetative index captured phenology of the
                    crop.
                  </p>
                  <h4 id="harvestprogression" className="common-sub-heading">
                    Harvest Progression
                  </h4>
                  <p className="common-para">
                    The area of crop harvested over a period is called Harvest Progression. The
                    harvest information is derived when the area is in progress of harvesting.
                  </p>
                  <label className="number"></label>
                </div>

                {/* <!-- Footer Logo --> */}
                <div className="footer-logo">
                  <div className="logo-icon">
                    {/* <img src={relianceLogo} alt="Reliance Logo" /> */}
                    {/* <img src={satsureLogo} alt="Satsure Logo" /> */}
                  </div>
                  {/* <div id="content">
                                <div id="pageFooter">Page </div>
                                multi-page content here...
                            </div> */}
                  {/* <div id="page-number"></div> */}
                  {/* <div class="page-number"></div> */}
                </div>
              </div>
            </div>
            {/* </PDFExport> */}

            {(() => {
              if (pageLoader) {
                return (
                  <div className="page-loader-sections">
                    <PageLoader />
                  </div>
                );
              }
            })()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="loader-container">
          <div className="loader">
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <>
        <div className="back-btn">
          <Button
            onClick={btnCallBack}
            variant="contained"
            className={classes.button}
            startIcon={<ArrowBackIcon />}>
            Back
          </Button>
        </div>
        {Object.keys(inputState).length !== 0 ? (
          <div className="document-text">Document Under Processing...</div>
        ) : null}
      </>
    );
  }
};
export default PdfComponent;
